import React, { useEffect } from "react";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Ratings from "../Ratings";
import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../../store/reducers/cardReducer";
import { CiLight } from "react-icons/ci";

const FeatureProducts = ({ products }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage, errorMessage } = useSelector((state) => state.card);

  // console.log("ppppppppppppp", products);

  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };
  return (
    <div className="w-[95%] flex flex-wrap mx-auto">
      <div className="w-full">
        <div className="text-center flex justify-center items-center flex-col xl:text-4xl sm:text-2xl text-[#3E2018] font-bold relative pb-[45px]">
          <div className="flex gap-5">
            <span class="title-star"> ★</span>
            <span className="uppercase">Most loved legends - best of all</span>
            {/* <span>Feature Products</span> */}
            <span class="title-star"> ★</span>
          </div>
          <div className="w-[100px] h-[4px] bg-[#7fad39] mt-4"></div>
        </div>
      </div>
      <div className="w-full grid grid-cols-5 md-lg:grid-cols-3 md:grid-cols-3  sm:grid-cols-2 xl:gap-7 sm:gap-2">
        {products.map(
          (p, i) =>
            i < 20 && (
              <Link
                to={`/product/details/${p.slug}`}
                key={i}
                className="border group transition-all duration-500 hover:shadow-md hover:-mt-3 rounded-lg overflow-hidden"
              >
                <div className="relative overflow-hidden">
                  {p.discount ? (
                    <div className="flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2">
                      {p.discount}%
                    </div>
                  ) : (
                    ""
                  )}
                  <img
                    className="sm:w-full w-full xl:h-[280px] sm:h-[200px]"
                    src={p.images[0]}
                    alt="product image"
                  />
                  <ul className="flex transition-all duration-700 -bottom-10 justify-center items-center gap-2 absolute w-full group-hover:bottom-3">
                    <li
                      onClick={() => add_wishlist(p)}
                      className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                    >
                      <AiFillHeart />
                    </li>
                    <Link
                      to={`/product/details/${p.slug}`}
                      className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                    >
                      <FaEye />
                    </Link>
                    <li
                      onClick={() => add_card(p._id)}
                      className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                    >
                      <AiOutlineShoppingCart />
                    </li>
                  </ul>
                </div>
                <div className="py-3 text-slate-600 px-2">
                  <h2>{p.name}</h2>
                  <div className="flex justify-start items-center gap-3">
                    <span className="text-lg  font-bold">₹{p.price}</span>
                    <div className="flex">
                      <Ratings ratings={p.rating} />
                    </div>
                  </div>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};

export default FeatureProducts;
