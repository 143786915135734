import React from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const ShippingPolicy = () => {
  return (
    <div>
      <Headers />

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Shipping & Returns Policy</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Cancellation on the Website by Customer
          </h2>
          <p className="text-gray-700 leading-relaxed">
            A customer can cancel an order anytime up to the time the order has
            been dispatched by calling our customer service. Once the order has
            been dispatched from our end, it cannot be cancelled. If we suspect
            any fraudulent transaction by any customer or any transaction that
            defies the terms & conditions of using the website, we at our sole
            discretion could cancel such orders with/without providing any
            notification to the Customer.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            We will maintain a negative list of all fraudulent transactions and
            customers and will deny access to them or cancel any orders placed
            by them.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Returns</h2>
          <p className="text-gray-700 leading-relaxed">
            Given that we are dealing with natural products, our company follows
            a no-return or exchange policy. We will only allow exchanges or
            returns if the product is damaged, empty, or has wrong/missing items
            at the time of delivery.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            Customers must share an unboxing video, a packaging defect video, as
            well as pictures of the damaged product or any item in question.
            Contact us at{" "}
            <a
              href="mailto:thevillagezone.info@gmail.com"
              className="text-blue-600"
            >
              thevillagezone.info@gmail.com
            </a>{" "}
            or call/WhatsApp us at{" "}
            <a href="tel:+917483991880" className="text-blue-600">
              +91 7483-991-880
            </a>{" "}
            within 48 hours of delivery.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Refunds</h2>
          <p className="text-gray-700 leading-relaxed">
            If the order has been cancelled, a refund will be reflected in your
            bank account within 7-10 days. If returning a damaged product, you
            will receive an email notifying approval or rejection of your
            refund.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            If approved, your refund will be processed within 7-10 days. If you
            haven't received a refund yet, check your bank account or contact
            your bank for more information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Shipping</h2>
          <p className="text-gray-700 leading-relaxed">
            To return your product, mail it to:
          </p>
          <p className="text-gray-700 leading-relaxed">
            PARISHUDH NATURAL FARM PRODUCTS, 105/2, behind Chennabasaveshwara
            Mutt, Chimlapur, Raichur, Karnataka, 584118.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
          <p className="text-gray-700 leading-relaxed">
            Contact us at{" "}
            <a
              href="mailto:thevillagezone.info@gmail.com"
              className="text-blue-600"
            >
              thevillagezone.info@gmail.com
            </a>{" "}
            or call/WhatsApp us at{" "}
            <a href="tel:+917483991880" className="text-blue-600">
              +91 7483-991-880
            </a>
            .
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingPolicy;
