import React from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Headers />
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h1 className="text-3xl font-semibold mb-4">Privacy Policy</h1>

          <p className="text-gray-700 mb-6">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="mt-5">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Interpretation and Definitions
          </h2>

          <h3 className="text-xl font-semibold mt-4 mb-2">Interpretation</h3>
          <p className="text-gray-700 mb-6">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. <br />
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>

          <h3 className="text-xl font-semibold mb-2">Definitions</h3>
          <p className="text-gray-700 mb-6">
            For the purposes of this Privacy Policy:
          </p>

          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              <span className="font-semibold">Account:</span> means a unique
              account created for You to access our Service or parts of our
              Service.
            </li>
            <li>
              <span className="font-semibold">Affiliate:</span> means an entity
              that controls, is controlled by or is under common control with a
              party, where "control" means ownership of 50% or more of the
              shares, equity interest or other securities entitled to vote for
              election of directors or other managing authority.
            </li>
            <li>
              <span className="font-semibold">Company:</span>(referred to as
              either "the Company", "We", "Us" or "Our" in this Agreement)
              refers to PARISHUDH NATURAL FARM PRODUCTS, 105/2,behind
              Chennabasaveshwara Mutt,chimlapur,raichur,karnataka, 584118
            </li>
            <li>
              <span className="font-semibold">Cookies:</span> are small files
              that are placed on Your computer, mobile device or any other
              device by a website, containing the details of Your browsing
              history on that website among its many uses.
            </li>
            <li>
              <span className="font-semibold">Country :</span> refers to:
              Karnataka, India
            </li>
            <li>
              <span className="font-semibold">Device :</span> means any device
              that can access the Service such as a computer, a cellphone or a
              digital tablet.
            </li>
            <li>
              <span className="font-semibold">Personal Data :</span> is any
              information that relates to an identified or identifiable
              individual.
            </li>
            <li>
              <span className="font-semibold">Service  :</span>  refers to the
              Website.
            </li>
            <li>
              <span className="font-semibold">Service Provider :</span>means any
              natural or legal person who processes the data on behalf of the
              Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the
              Service on behalf of the Company, to perform services related to
              the Service or to assist the Company in analyzing how the Service
              is used.
            </li>
            <li>
              <span className="font-semibold">Usage Data  :</span>refers to data
              collected automatically, either generated by the use of the
              Service or from the Service infrastructure itself (for example,
              the duration of a page visit).
            </li>
            <li>
              <span className="font-semibold">Website :</span>refers to website.
            </li>
            <li>
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
          </ul>

          {/* Add other sections in a similar format */}
          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Collecting and Using Your Personal Data
          </h2>

          <h3 className="text-xl font-semibold mb-2">
            Types of Data Collected
          </h3>
          <h3 className="text-xl font-semibold mb-2">Personal Data</h3>
          <p className="text-gray-700 mb-6">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information, including:
          </p>

          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>Email address</li>
            <li>Usage Data</li>
          </ul>
          <div className="mt-5 space-y-5">
            <h3 className="text-xl font-semibold mb-2">Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
          </div>

          {/* Footer Section */}
          <div className="mt-10">
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p className="text-gray-700 mt-2">
              If you have any questions about this Privacy Policy, You can
              contact us via email at:{" "}
              <a
                href="mailto:thevillagezone.info@gmail.com"
                className="text-blue-500 underline"
              >
                thevillagezone.info@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
