import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./bannerstyle.css";

const Banner = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const banner1 = [
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729185747/11_p47ykl.webp",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729185849/7_c7dfiv.webp",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729185744/9_nox1se.webp",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729186653/10_bbwxks.webp",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729186653/8_u72dty.webp",
    },
  ];
  const banner2 = [
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729189889/7_es18ub.png",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729189889/4_brthzp.png",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729189890/6_uvfkfa.png",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729189890/5_u6d6o9.png",
    },
    {
      banner:
        "https://res.cloudinary.com/du8xwgsse/image/upload/v1729189891/3_2_vy1iiq.png",
    },
  ];

  return (
    <div className="w-full md-lg:mt-6">
      <div className="xl:w-[100%] md-lg:w-[100%] mx-auto">
        <div className="w-full flex flex-wrap md-lg:gap-8">
          <div className="w-full">
            <div className="xl:mt-8 md-lg:mt-0 ">
              <Carousel
                autoPlay={true}
                infinite={true}
                arrows={true}
                showDots={true}
                responsive={responsive}
              >
                {banner1.map((img, i) => (
                  <Link
                    className="h-full w-full sm:hidden xl:flex "
                    key={i}
                    to="#"
                  >
                    <img
                      className="md-lg:h-[12rem] w-full"
                      src={img.banner}
                      alt=""
                    />
                  </Link>
                ))}
              </Carousel>
              <Carousel
                autoPlay={true}
                infinite={true}
                arrows={true}
                showDots={true}
                responsive={responsive}
              >
                {banner2.map((img, i) => (
                  <Link
                    className="h-full w-full sm:flex xl:hidden "
                    key={i}
                    to="#"
                  >
                    <img
                      className="md-lg:h-[12rem] w-full"
                      src={img.banner}
                      alt=""
                    />
                  </Link>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
