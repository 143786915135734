import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AiFillShopping, AiFillHeart } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import logo from "../assets/logo1.svg";
import Amazon from "../assets/amazon.svg";
import Bigbasket from "../assets/bigbasket.svg";
import Visa from "../assets/visa.jpg";
import Credit from "../assets/credit-card.jpg";
import Paypal from "../assets/paypal.jpg";

import "./footer.css";
const Footer = () => {
  const { card_product_count, wishlist_count } = useSelector(
    (state) => state.card
  );
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      <footer>
        <div className="container-section">
          <div className="grid grid-cols-12 gap-4 mat-25">
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 xl:col-span-3">
              <ul className="footer-sec">
                <li>
                  <img src={logo} alt="" className="w-52" />
                </li>
                <li className="mt-2">Also shop on</li>
                <li className="mt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Minima molestiae impedit, nulla quam repudiandae odit sequi.
                  Odio sunt accusantium deleniti?
                </li>
                <li className="mt-2">{/* <img src={Bigbasket} alt="" /> */}</li>
              </ul>
            </div>

            {/* <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 xl:col-span-3">
              <h5 className="heading-h5">Shop</h5>
              <ul className="footer-sec">
                <li>Careals for Little Ones</li>
                <li>Kids Careals</li>
                <li>Pancakes & Dosas</li>
                <li>Noodles & Pasta</li>
                <li>Natural Sweeteners</li>
                <li>Superfoods</li>
              </ul>
            </div> */}
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 xl:col-span-3">
              <h5 className="heading-h5">Policies</h5>
              <ul className="footer-sec">
                <Link to="/policy">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/shipping-policy">
                  <li>Shipping & Refund</li>
                </Link>
                <Link to="/terms-condition">
                  <li>Terms & Conditions</li>
                </Link>
              </ul>
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 xl:col-span-3">
              <h5 className="heading-h5">About</h5>
              <ul className="footer-sec">
                <Link to="/about">
                  <li>About Us</li>
                </Link>
                <Link to="/contact">
                  <li>Contact Us</li>
                </Link>
                <Link to="/shops">
                  <li>Shop </li>
                </Link>
                <Link to="/dashboard">
                  <li>My Dashboard</li>
                </Link>
                <Link to="/blog">
                  <li>Blogs </li>
                </Link>
                <Link to="/login">
                  <li>Log Out</li>
                </Link>
              </ul>
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 xl:col-span-3">
              <h5 className="heading-h5">Subscribe to our emails</h5>
              <p className="mt-5">
                Be the first to know about our biggest and best sales.
              </p>
              <div className="f-email mt-6">
                <input type="email" placeholder="Enter e-mail id" />
                <span>
                  <MdArrowForwardIos />
                </span>
              </div>
              <p className="mt-[30px]">Payment methods</p>
              <ul className="payment-sec">
                <li>
                  {" "}
                  <img src={Visa} alt="" />{" "}
                </li>
                <li>
                  {" "}
                  <img src={Credit} alt="" />{" "}
                </li>
                <li>
                  {" "}
                  <img src={Paypal} alt="" />{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full text-center text-white bg-gray-700 border-t-2">
          <div className="py-3">
            <p>© Copyright © 2024 with omgeniustect.com, All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
