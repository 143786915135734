import React from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <Headers />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>

        <section className="mb-8">
          <p className="text-gray-700 leading-relaxed">
            Welcome to THE VILLAGE ZONE! These terms and conditions outline the
            rules and regulations for the use of PARISHUDH NATURAL FARM
            PRODUCTS, Website, located at THE VILLAGE ZONE WEBSITE.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use NATURAL PRODUCTS FROM THE VILLAGE
            ZONE if you do not agree to take all of the terms and conditions
            stated on this page.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Cookies</h2>
          <p className="text-gray-700 leading-relaxed">
            We employ the use of cookies. By accessing THE VILLAGE ZONE, you
            agreed to use cookies in agreement with the PARISHUDH NATURAL FARM
            PRODUCTS’ Privacy Policy.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            Cookies help enhance functionality of certain areas and make it
            easier for visitors. Some of our affiliate/advertising partners may
            also use cookies.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">License</h2>
          <p className="text-gray-700 leading-relaxed">
            Unless otherwise stated, PARISHUDH NATURAL FARM PRODUCTS and/or its
            licensors own the intellectual property rights for all material on
            THE VILLAGE ZONE. All intellectual property rights are reserved. You
            may access this from THE VILLAGE ZONE for your own personal use
            subjected to restrictions set in these terms and conditions.
          </p>
          <ul className="list-disc ml-6 text-gray-700 mt-2">
            <li>You must not republish material from THE VILLAGE ZONE</li>
            <li>Sell, rent, or sub-license material from THE VILLAGE ZONE</li>
            <li>Reproduce, duplicate or copy material from THE VILLAGE ZONE</li>
            <li>Redistribute content from THE VILLAGE ZONE</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Comments</h2>
          <p className="text-gray-700 leading-relaxed">
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information. PARISHUDH NATURAL FARM PRODUCTS
            does not filter, edit, or review comments prior to their presence on
            the website. Comments reflect the views of the person who posts
            them. We reserve the right to remove inappropriate comments.
          </p>
          <p className="text-gray-700 leading-relaxed mt-2">
            By posting comments, you warrant and represent that you are entitled
            to post them, and they do not infringe any third-party rights or
            contain unlawful material.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Hyperlinking to our Content
          </h2>
          <p className="text-gray-700 leading-relaxed">
            Organizations such as government agencies, search engines, news
            organizations, and online directories may link to our Website
            without prior written approval. We reserve the right to approve or
            deny link requests from other organizations as outlined in this
            policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">iFrames</h2>
          <p className="text-gray-700 leading-relaxed">
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter the visual presentation or
            appearance of our Website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Content Liability</h2>
          <p className="text-gray-700 leading-relaxed">
            We shall not be held responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            arising from your Website. No link(s) should appear on any Website
            that may be interpreted as defamatory, obscene, or criminal.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
          <p className="text-gray-700 leading-relaxed">
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties, and conditions relating to our website
            and the use of this website. This disclaimer covers liabilities
            arising in contract, tort, or breach of statutory duty.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="text-gray-700 leading-relaxed">
            If you have any questions about these terms, please contact us at{" "}
            <a
              href="mailto:thevillagezone.info@gmail.com"
              className="text-blue-600"
            >
              thevillagezone.info@gmail.com
            </a>{" "}
            or call/WhatsApp us at{" "}
            <a href="tel:+917483991880" className="text-blue-600">
              +91 7483-991-880
            </a>
            .
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
