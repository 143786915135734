import React, { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  add_to_card,
  add_to_wishlist,
  messageClear,
} from "../store/reducers/cardReducer";
import { FaEye, FaRegHeart } from "react-icons/fa";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import toast from "react-hot-toast";

const RecipeProduct = ({ filteredProduct }) => {
  // console.log("filteredProduct1111", filteredProduct);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage, errorMessage } = useSelector((state) => state.card);

  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };
  return (
    <div className="row">
      <div className="">
        <h3 className="mb-10 text-xl font-medium text-center roboto-medium">
          Shop items used in the recipe
          <hr className="bg-yellow-300 h-2 rounded-full mt-2 w-20 mx-auto" />
        </h3>
        <div className="grid grid-cols-12 gap-3">
          {filteredProduct.map((pro, i) => (
            <div className="xl:col-span-3 md:col-span-6 sm:col-span-12">
              <div className="product-cart relative overflow-hidden group-hover: h-[30rem]">
                <div className="absolute top-[5%] right-5 duration-300">
                  <li
                    onClick={() => add_wishlist(pro)}
                    className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                  >
                    <AiFillHeart />
                  </li>
                </div>
                <div className="cart-image flex flex-col justify-center items-center">
                  <div className="flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2">
                    {pro.discount}%
                  </div>
                  <img
                    src={pro.images[0]}
                    alt="watch"
                    className="watch w-full h-full"
                  />
                </div>
                <div className="product-detail px-2">
                  <h6 className="brand text-red-500">{pro.brand}</h6>
                  <h5 className="product-title my-3 text-black font-semibold text-lg">
                    {pro.name}
                  </h5>
                  <ReactStars count={5} size={24} activeColor="#ffd700" />
                  <p className="price text-gray-700 text-lg font-bold">
                    ₹ {pro.price}
                  </p>
                </div>

                <div className="action-bar group absolute top-[15%] right-[-30px] duration-300">
                  <div className="flex flex-col gap-5">
                    <ul>
                      <Link
                        to={`/product/details/${pro.slug}`}
                        className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                      >
                        <FaEye />
                      </Link>
                      <li
                        onClick={() => add_card(pro._id)}
                        className="w-[38px] h-[38px] mt-2 cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#7fad39] hover:text-white hover:rotate-[720deg] transition-all"
                      >
                        <AiOutlineShoppingCart />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecipeProduct;
