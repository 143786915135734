import React from "react";
import Headers from "../components/Headers";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { get_recipes } from "../store/reducers/recipeReducer";
import { useEffect } from "react";
import { BiCookie } from "react-icons/bi";

const Racipe = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, errorMessage, loader, recipes } = useSelector(
    (state) => state.recipe
  );

  useEffect(() => {
    dispatch(get_recipes());
  }, [dispatch]);

  // console.log("get_recipes on recipesss1", recipes);
  return (
    <div>
      <Headers />
      <div>
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
          <div className="border-b mb-5 flex justify-between text-sm">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase gap-5">
              <span className="text-2xl">
                <BiCookie />
              </span>
              <Link to="/recipe" className="font-semibold inline-block">
                Cooking Recipe
              </Link>
            </div>
            <Link to={"/racipe"} href="#">
              See All
            </Link>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-10">
            {/* CARD 1 */}
            {recipes.map((rec, i) => (
              <div
                className="rounded overflow-hidden shadow-lg flex flex-col"
                onClick={() => navigate(`/recipes?slug=${rec.slug}`)}
                key={rec.slug}
              >
                <a href="#" />
                <div className="relative">
                  <img className="w-full" src={rec.images[0]} alt={rec.name} />
                  <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>

                  <div className="text-xs absolute top-0 right-0 bg-indigo-600 px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                    Cooking
                  </div>
                </div>
                <div className="px-6 py-4 mb-auto">
                  <div className="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2">
                    {rec.name}
                  </div>
                  <p className="text-gray-500 text-sm">{rec.subtitle}</p>
                </div>
              </div>
            ))}
            {/* CARD 2 */}
            {/* <div className="rounded overflow-hidden shadow-lg flex flex-col">
              <a href="#" />
              <div className="relative">
                <a href="#">
                  <img
                    className="w-full"
                    src="https://images.pexels.com/photos/1600727/pexels-photo-1600727.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    alt="Sunset in the mountains"
                  />
                  <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                </a>
                <a href="#!">
                  <div className="text-xs absolute top-0 right-0 bg-indigo-600 px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                    Cooking
                  </div>
                </a>
              </div>
              <div className="px-6 py-4 mb-auto">
                <a
                  href="#"
                  className="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
                >
                  Best FastFood Ideas (Yummy)
                </a>
                <p className="text-gray-500 text-sm">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div> */}
            {/* CARD 3 */}
            {/* <div className="rounded overflow-hidden shadow-lg flex flex-col">
              <a href="#" />
              <div className="relative">
                <a href="#">
                  <img
                    className="w-full"
                    src="https://images.pexels.com/photos/6086/food-salad-healthy-vegetables.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    alt="Sunset in the mountains"
                  />
                  <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                </a>
                <a href="#!">
                  <div className="text-xs absolute top-0 right-0 bg-indigo-600 px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                    Cooking
                  </div>
                </a>
              </div>
              <div className="px-6 py-4 mb-auto">
                <a
                  href="#"
                  className="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
                >
                  Why to eat salad?
                </a>
                <p className="text-gray-500 text-sm">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Racipe;
