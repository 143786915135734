import React from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
      <Headers />
      <section className="w-full mx-auto py-10 bg-white dark:bg-gray-900 dark:text-white">
        {/* <div className="w-fit pb-1 px-2 mx-4 rounded-md text-2xl font-semibold border-b-2 border-blue-600 dark:border-b-2 dark:border-yellow-600">Services</div> */}
        <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex xl:flex-row md:flex-col xl:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
          <div className="xl:w-[30%] md:w-full xl:mr-6">
            <img
              className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm"
              src="https://res.cloudinary.com/du8xwgsse/image/upload/v1729191864/about_zw9cea.jpg"
              alt="billboard image"
            />
          </div>
          <div className="xl:w-[70%] md:w-full xs:w-full bg-white dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
            <h2 className="text-3xl font-semibold text-gray-900 dark:text-white">
              Welcome to The Village Zone
            </h2>
            <p className="text-md mt-4 text-justify">
              We're Chamaraja S Patil and Hithaish patil, a father-son duo
              passionate about cultivating life through natural farming
              practices. Our journey began with a simple desire to grow healthy
              food for our family and community, and has evolved into a mission
              to regenerate the land, promote sustainability, and inspire others
              to join the natural farming movement.
            </p>
          </div>
        </div>
        {/* col-2 */}
        <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex xl:flex-row md:flex-col-reverse xl:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6">
          <div className="xl:w-[70%] md:w-full bg-white dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
            <h2 className="text-3xl font-semibold text-gray-900 dark:text-white">
              Our Story
            </h2>
            <p className="text-md mt-4 text-justify">
              Growing up, I chamaraja patil learned the importance of living in
              harmony with nature from my parents and grandparents Who performed
              natural farming,I started my journey of Natural farming in 2010,my
              son Hithaish joined this journey at the age of 18, started
              embracing ancient techniques and modern innovations.The Village
              Zone believes in Ancient Methods of Natural Farming . Eat Natural
              , Stay Natural and Healthy. Decades of experience in Ancient
              Methods of Natural Farming by our Forefathers which is our
              Strength and is sustainable in nature, Good for mankind.
            </p>
          </div>
          {/*  */}
          <div className="md:block xs:hidden xl:w-[30%] md:w-full">
            <img
              className="lg:rounded-t-lg xs:rounded-sm"
              src="https://res.cloudinary.com/du8xwgsse/image/upload/v1729191864/about2_wq75rv.jpg"
              alt="billboard image"
            />
          </div>
        </div>
      </section>

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
      />
      <section id="new-features" className="py-8 bg-white sm:py-10 lg:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="md:text-2xl font-bold leading-tight text-gray-900  xl:text-5xl">
              Our Philosophy of The Village Zone
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8">
              We believe that natural farming is not just a method, but a
              passion .
            </p>
            <p className="mt-4">"Healthy Soil, Healthy Food, Healthy Life"</p>
          </div>
          {/* <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-1 sm:gap-x-12 gap-y-12 md:grid-cols-3 xl:grid-cols-3 md:gap-0 xl:mt-24">
           
            <div className="md:p-8 lg:p-14 flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-purple-200 flex justify-center items-center">
                <i className="fa-solid fa-chart-column text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Advanced Analytics
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Track and analyze your data with powerful analytics tools. Gain
                valuable insights for better decision-making.
              </p>
            </div>
         
            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-teal-200 flex justify-center items-center">
                <i className="fa-solid fa-truck-fast text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Fast Integration
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Seamlessly integrate with your existing tools and systems for a
                smooth workflow experience.
              </p>
            </div>
           
            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-yellow-200 flex justify-center items-center">
                <i className="fa-solid fa-shield text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Security First
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Ensure the safety of your data with top-notch security features.
                Your privacy is our priority.
              </p>
            </div>
          
            <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200 flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-red-200 flex justify-center items-center">
                <i className="fa-solid fa-cloud text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Cloud Integration
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Access your data from anywhere with seamless cloud integration.
                Work without boundaries.
              </p>
            </div>
          
            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-green-200 flex justify-center items-center">
                <i className="fa-solid fa-pen-nib text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Task Management
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Organize your workflow with efficient task management features.
                Stay on top of your projects effortlessly.
              </p>
            </div>
          
            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t flex flex-col justify-center items-center">
              <div className="w-14 h-14 rounded-full bg-orange-200 flex justify-center items-center">
                <i className="fa-solid fa-bolt text-3xl text-gray-900" />
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">
                Performance Metrics
              </h3>
              <p className="mt-5 text-base text-gray-600">
                Monitor and measure your performance with comprehensive metrics.
                Optimize your processes for maximum efficiency.
              </p>
            </div>
          </div> */}
          <div className="w-[90%] mx-auto mt-10">
            <p className="text-xl font-semibold"> By working with nature, </p>
            <ul className="text-lg list-disc space-y-5">
              <li>Nourish the soil through composting and cover cropping</li>
              <li>Conserve water through efficient irrigation systems</li>
              <li>
                Promote biodiversity through crop rotation and polycultures
              </li>
              <li>
                Enhance soil carbon sequestration through regenerative practices
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
