import React, { useState } from "react";
import Fruits from "../assets/fruits.jpg";
import { CgProfile } from "react-icons/cg";
import { CiCalendar } from "react-icons/ci";
import { VscComment } from "react-icons/vsc";
import { IoSearchOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import Im1 from "../assets/bhuna-meat.webp";
import Headers from "../components/Headers";

import { useSelector, useDispatch } from "react-redux";
import { get_blogs } from "../store/reducers/blogReducer";
import { useEffect } from "react";
import Footer from "../components/Footer";

const BlogDetails = () => {
  const [fix, setFix] = useState(false);
  const comment = localStorage.getItem("commentCount");

  const dispatch = useDispatch();
  const { successMessage, errorMessage, loader, blogs } = useSelector(
    (state) => state.blog
  );

  // console.log("comment", comment);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("slug"); // Extract slug from URL

  console.log("slug", slug);

  const [filteredBlog, setFilteredBlog] = useState(null);
  // const [filteredProduct, setFilteredProduct] = useState([]);

  useEffect(() => {
    dispatch(get_blogs());
  }, [dispatch]);

  useEffect(() => {
    if (blogs && slug) {
      const blog = blogs.find((blo) => blo.slug == slug);
      setFilteredBlog(blog);
    }
  }, [blogs, slug]);

  // useEffect(() => {
  //   if (blogs && slug) {
  //     const matchingProducts = blogs.filter((blog) => blog.slug == blogs.slug);
  //     setFilteredBlog(matchingProducts); // Store as an array
  //   }
  // }, [blogs, slug]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" }); // e.g., 'May'
    const day = date.getDate(); // e.g., 10
    const year = date.getFullYear(); // e.g., 2024

    return { month, day, year };
  };

  const { month, day, year } = formatDate(filteredBlog?.updatedAt);

  // console.log("get_blogs on blogseeeeeeeeeeeeeeeeeeeess1", filteredBlog);

  function setFixSidebar() {
    if (window.scrollY >= 500) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixSidebar);
  return (
    <>
      <Headers />
      <div className="bg-white">
        <div className="my-10 flex items-start">
          <div className="w-[75%] px-3 mr-3">
            <main className="border-[1px] border-gray-300 rounded-md">
              <div className="mb-8 p-3">
                <img
                  className="rounded-md"
                  src={filteredBlog?.images[0]}
                  alt=""
                />
              </div>
              <div className="p-6">
                <div className="mb-1">
                  <Link
                    className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                    to=""
                  >
                    {filteredBlog?.brand}
                  </Link>
                  <h1 className="mb-2 text-[36px] font-sans font-semibold">
                    {filteredBlog?.name}
                  </h1>
                </div>
                <ul className="flex justify-start items-center mb-4">
                  <li className="flex justify-start items-center mr-5">
                    <i className="pr-2">
                      <CgProfile />
                    </i>
                    By
                    <Link to="" className="ms-1">
                      Admin
                    </Link>
                  </li>
                  <li className="flex justify-start items-center mr-5">
                    <i className="pr-2">
                      <CiCalendar />
                    </i>
                    <span>{day} </span> - <span> {month}</span> -
                    <span> {year}</span>
                  </li>
                  <li className="flex justify-start items-start">
                    <i className="pr-2 text-center">
                      <VscComment />
                    </i>
                    <span>{comment} </span> <span> Comments</span>
                  </li>
                </ul>
                <div>
                  <p className="mb-5 text-[1rem] font-normal">
                    {filteredBlog.description}
                  </p>
                </div>
                <div className="mt-7">
                  <Link
                    className="px-6 py-4 mb-2 text-[#f7f7f7] bg-[#1bb057] rounded-3xl text-[15px] font-medium"
                    to=""
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </main>
          </div>
          <div
            className="blog-sidebar bg-white w-[25%] h-[100%] px-3 "
            id={fix ? "fix" : ""}
          >
            <aside>
              <div className="text-[14px] px-10 pt-8 pb-10  mb-8 border-[1px] border-gray-300 rounded-md">
                <div>
                  <h3 className="mb-5 text-[18px]">Search</h3>
                </div>
                <form action="">
                  <div className="flex justify-center items-center">
                    <input
                      type="text"
                      placeholder="Search Here ..."
                      className="py-2 pl-4 pr-3 rounded-l-lg bg-[#d7d7d7]"
                    />
                    <button className="px-3 py-2 ml-[-1px] bg-yellow-500 w-[60px] h-[37px] rounded-r-lg text-black text-[18px] flex justify-center items-center">
                      <i className="">
                        <IoSearchOutline />
                      </i>
                    </button>
                  </div>
                </form>
                <div></div>
              </div>

              <div className="text-[14px] px-10 pt-8 pb-10  mb-8 border-[1px] border-gray-300 rounded-md ">
                <div className="mb-5 text-[18px]">
                  <h3>Latest Post</h3>
                </div>
                {blogs.map((item) => {
                  // Format the date to 'dd-mm-yyyy'
                  const formattedDate = new Date(item.updatedAt)
                    .toLocaleDateString("en-GB")
                    .replace(/\//g, "-"); // Replace '/' with '-'

                  return (
                    <div
                      className="w-[100%] flex justify-center items-center pb-8"
                      key={item.id}
                    >
                      <div className="w-[40%] mr-4">
                        <img
                          className="w-[100%] h-[85px]"
                          src={item.images[0]}
                          alt={item.name}
                        />
                      </div>
                      <div className="w-[60%]">
                        <div className="flex justify-start items-center mr-5 mb-2">
                          <i className="pr-2">
                            <CiCalendar />
                          </i>
                          {formattedDate} {/* Display the formatted date */}
                        </div>
                        <h4 className="text-[14px] font-semibold">
                          <Link to="">{item.name}</Link>
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="text-[14px] px-10 pt-8 pb-10  mb-8 border-[1px] border-gray-300 rounded-md">
                <div className="">
                  <h3 className="mb-5 text-[18px]">Tags</h3>
                </div>

                <div className="flex flex-wrap gap-1">
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Cosmetics
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Drinks
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Food
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Fruits
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Nutrition
                    </Link>
                  </div>
                  <div className="mb-3">
                    <Link
                      className="px-4 py-2 mb-2 text-[#1b8057] bg-[#f7f7f7] rounded-3xl"
                      to=""
                    >
                      Vegetables
                    </Link>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
