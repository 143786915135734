import React from "react";
import Headers from "../components/Headers";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { get_blogs } from "../store/reducers/blogReducer";
import { useEffect } from "react";
import { ImBlogger } from "react-icons/im";
import { useState } from "react";

const Blog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, errorMessage, loader, blogs } = useSelector(
    (state) => state.blog
  );
  // const [comment, setComment] = useState()

  useEffect(() => {
    dispatch(get_blogs());
  }, [dispatch]);

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const randomCommentCount = getRandomNumber(10, 50);

  const calculateTimeDifference = (updatedAt) => {
    const uploadedDate = new Date(updatedAt);
    const now = new Date();

    const diffInMs = now - uploadedDate;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) return `${diffInMinutes} mins ago`;
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    return `${diffInDays} days ago`;
  };

  const getRandomCommentCount = () =>
    Math.floor(Math.random() * (50 - 10 + 1)) + 10; // Random between 10 and 50

  const setComment = (comment) => {
    localStorage.setItem("commentCount", comment); // Store the comment count with a key
  };
  // console.log("get_blogs on blogsss1", blogs);

  return (
    <div>
      <Headers />
      <div>
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
          <div className="border-b mb-5 flex justify-between text-sm">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 gap-5 uppercase">
              <ImBlogger />
              <a href="#" className="font-semibold inline-block">
                Product BLogs
              </a>
            </div>
            <a href="#">See All</a>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-10">
            {/* CARD 1 */}
            {blogs.map((blog, i) => {
              const timeAgo = calculateTimeDifference(blog.updatedAt);
              const randomCommentCount = getRandomCommentCount();

              return (
                <div
                  key={i}
                  className="rounded overflow-hidden shadow-lg flex flex-col"
                  onClick={() => {
                    navigate(`/blog-details?slug=${blog.slug}`);
                    setComment(randomCommentCount); // Store comment count in localStorage
                  }}
                >
                  <div className="relative">
                    <img
                      className="w-full h-[250px]"
                      src={blog.images[0]}
                      alt={blog.name}
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                  </div>
                  <div className="px-6 py-4 mb-auto">
                    <div
                      href="#"
                      className="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
                    >
                      {blog.name}
                    </div>
                    <p className="text-gray-500 text-sm">{blog.subtitle}</p>
                  </div>
                  <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
                    <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
                      <svg
                        height="13px"
                        width="13px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{ enableBackground: "new 0 0 512 512" }}
                      >
                        <g>
                          <g>
                            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256 c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128 c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"></path>
                          </g>
                        </g>
                      </svg>
                      <span className="ml-1">{timeAgo}</span>
                    </span>
                    <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
                      <svg
                        className="h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        ></path>
                      </svg>
                      <span className="ml-1">
                        {randomCommentCount} Comments
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
